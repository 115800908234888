

const Error = () => {
    return (
        <div className="Error">
            <h1>404</h1>
            <br />
            <p>Page not found</p>
        </div>
    )
}

export default Error;